<template>
  <img
    v-if="icons[name].type === 'assets'"
    :class="['block select-none align-middle', icons[name].customStyle]"
    :style="`width: ${size || 'auto'}; min-width: ${size || 'auto'};`"
    :src="getIcon(`${icons[name].name}.${icons[name].extension}`)"
    :alt="icons[name].name"
  />

  <span
    v-else
    :class="[
      'block select-none align-middle',
      style ? styleToMaterialIconsStyle[style] : icons[name].type,
      icons[name].customStyle,
    ]"
    :style="`font-size: ${size || 'inherit'};`"
  >
    {{ icons[name].name }}
  </span>
</template>

<script setup lang="ts">
export type IconName =
  | "Add"
  | "AddFolder"
  | "Analytics"
  | "Apartment"
  | "Apps"
  | "ArrowDown"
  | "ArrowDropDown"
  | "ArrowDropUp"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowRightAlt"
  | "ArrowUp"
  | "Attachment"
  | "Briefcase"
  | "BulletList"
  | "Calendar"
  | "Camera"
  | "Clock"
  | "Copy"
  | "ChartBar"
  | "Chat"
  | "Check"
  | "CheckCircle"
  | "Checklist"
  | "Click"
  | "Close"
  | "CreditCard"
  | "Crop_5_4"
  | "Crop_16_9"
  | "DarkMode"
  | "Drag"
  | "Delete"
  | "Download"
  | "Door"
  | "Email"
  | "Error"
  | "ExpandFullscreen"
  | "Facebook"
  | "Filter"
  | "File"
  | "Flag"
  | "CompanyFolder"
  | "FolderOff"
  | "FullscreenExit"
  | "GoBack"
  | "HistoryToggleOff"
  | "Info"
  | "Instagram"
  | "Image"
  | "Language"
  | "LightMode"
  | "Linkedin"
  | "List"
  | "Logout"
  | "Lock"
  | "LockOpen"
  | "Mail"
  | "MasterCard"
  | "Menu"
  | "Modal"
  | "More"
  | "Minimize"
  | "Numbers"
  | "Notifications"
  | "Fullscreen"
  | "Pagination"
  | "Pause"
  | "PasswordVisibility"
  | "PasswordVisibilityOff"
  | "Pencil"
  | "Person"
  | "PersonalContent"
  | "PersonalFolder"
  | "Play"
  | "Phone"
  | "Pin"
  | "QuestionMark"
  | "Reply"
  | "Recommended"
  | "Loading"
  | "Sad"
  | "Search"
  | "Settings"
  | "Send"
  | "ShoppingCart"
  | "Skeleton"
  | "SortBy"
  | "Success"
  | "Subject"
  | "Star"
  | "StarHalf"
  | "SubdirectoryArrow"
  | "Table"
  | "Terms"
  | "Text"
  | "Time"
  | "TrashBin"
  | "Visa"
  | "VolumeDown"
  | "VolumeUp"
  | "VolumeOff"
  | "Upload"
  | "UserGroup"
  | "Warning"
  | "Export"
  | "Sparkle"
  | "SearchSparkle"
  | "ConversationResult"
  | "EditChat"
  | "Square"
  | "Write";

import { getIcon } from "../utils";

withDefaults(
  defineProps<{
    name: IconName;
    size?: string;
    style?: "filled" | "outlined" | "round";
  }>(),
  {
    size: undefined,
    style: undefined,
  }
);

export type IconStyle = "filled" | "outlined" | "round";

const styleToMaterialIconsStyle = {
  filled: "material-icons",
  outlined: "material-icons-outlined",
  round: "material-icons-round",
};

type Icon = {
  [Key in IconName]: {
    name: string;
    type: "assets" | "material-icons" | "material-icons-outlined" | "material-icons-round";
    extension?: "svg" | "png";
    customStyle?: string;
  };
};

const icons: Icon = {
  Add: { name: "add", type: "material-icons" },
  AddFolder: { name: "create_new_folder", type: "material-icons-outlined" },
  Analytics: { name: "trending_up", type: "material-icons" },
  Apartment: { name: "apartment", type: "material-icons" },
  Apps: { name: "apps", type: "material-icons" },
  ArrowDown: { name: "keyboard_arrow_down", type: "material-icons" },
  ArrowDropDown: { name: "arrow_drop_down", type: "material-icons" },
  ArrowDropUp: { name: "arrow_drop_up", type: "material-icons" },
  ArrowLeft: { name: "keyboard_arrow_left", type: "material-icons" },
  ArrowRight: { name: "keyboard_arrow_right", type: "material-icons" },
  ArrowRightAlt: { name: "arrow_right_alt", type: "material-icons" },
  ArrowUp: { name: "keyboard_arrow_up", type: "material-icons" },
  Attachment: { name: "attach_file", type: "material-icons" },
  Briefcase: { name: "business_center", type: "material-icons" },
  BulletList: { name: "format_list_bulleted", type: "material-icons" },
  Calendar: { name: "calendar_month", type: "material-icons" },
  Camera: { name: "camera_alt", type: "material-icons-outlined" },
  ChartBar: { name: "insert_chart", type: "material-icons" },
  Clock: { name: "schedule", type: "material-icons-outlined" },
  Chat: { name: "chat", type: "material-icons-outlined" },
  Check: { name: "check", type: "material-icons" },
  CheckCircle: { name: "check_circle", type: "material-icons" },
  Checklist: { name: "checklist", type: "material-icons" },
  Click: { name: "ads_click", type: "material-icons" },
  Close: { name: "close", type: "material-icons" },
  FullscreenExit: { name: "fullscreen_exit", type: "material-icons" },
  Copy: { name: "content_copy", type: "material-icons" },
  CreditCard: { name: "credit_card", type: "material-icons" },
  Crop_5_4: { name: "crop_16_9", type: "material-icons" },
  Crop_16_9: { name: "crop_7_5", type: "material-icons" },
  DarkMode: { name: "dark_mode", type: "material-icons" },
  Drag: { name: "zoom_out_map", type: "material-icons" },
  Delete: { name: "delete", type: "material-icons-outlined" },
  Download: { name: "download", type: "material-icons" },
  Door: { name: "sensor_door", type: "material-icons-outlined" },
  Email: { name: "email", type: "material-icons-outlined" },
  Error: { name: "error_outline", type: "material-icons-outlined" },
  ExpandFullscreen: { name: "fullscreen", type: "material-icons" },
  Facebook: { name: "facebook", type: "assets", extension: "svg" },
  Filter: { name: "filter_list", type: "material-icons" },
  File: { name: "description", type: "material-icons-outlined" },
  Flag: { name: "flag", type: "material-icons-outlined" },
  CompanyFolder: { name: "topic", type: "material-icons-outlined" },
  FolderOff: { name: "folder_off", type: "material-icons-outlined" },
  GoBack: { name: "keyboard_backspace", type: "material-icons" },
  Info: { name: "info", type: "material-icons-outlined" },
  Instagram: { name: "instagram", type: "assets", extension: "svg" },
  Image: { name: "image", type: "material-icons-outlined" },
  Language: { name: "language", type: "material-icons" },
  LightMode: { name: "light_mode", type: "material-icons-outlined" },
  Linkedin: { name: "linkedin", type: "assets", extension: "svg" },
  Logout: { name: "logout", type: "material-icons" },
  Loading: { name: "autorenew", type: "material-icons" },
  Lock: { name: "lock", type: "material-icons" },
  LockOpen: { name: "lock_open", type: "material-icons" },
  HistoryToggleOff: { name: "history_toggle_off", type: "material-icons-outlined" },
  MasterCard: { name: "master_card", type: "assets", extension: "svg" },
  Menu: { name: "menu", type: "material-icons" },
  Modal: { name: "domain_verification", type: "material-icons" },
  Minimize: { name: "minimize", type: "material-icons-outlined" },
  More: { name: "more_vert", type: "material-icons" },
  Numbers: { name: "123", type: "material-icons" },
  Notifications: { name: "notifications", type: "material-icons-outlined" },
  Mail: { name: "mail", type: "material-icons-outlined" },
  Fullscreen: { name: "open_in_full", type: "material-icons" },
  Pagination: { name: "code", type: "material-icons-outlined" },
  Pause: { name: "pause", type: "material-icons" },
  PasswordVisibility: { name: "visibility", type: "material-icons-outlined" },
  PasswordVisibilityOff: { name: "visibility_off", type: "material-icons-outlined" },
  Pencil: { name: "edit", type: "material-icons-outlined" },
  Person: { name: "person", type: "material-icons-outlined" },
  PersonalContent: { name: "folder_shared", type: "material-icons-outlined" },
  PersonalFolder: { name: "folder_shared", type: "material-icons-outlined" },
  Play: { name: "play_arrow", type: "material-icons" },
  Phone: { name: "phone", type: "material-icons-outlined" },
  Pin: { name: "push_pin", type: "material-icons-outlined" },
  QuestionMark: { name: "question_mark", type: "material-icons" },
  Reply: { name: "reply", type: "material-icons", customStyle: "scale-x-[-1]" },
  Recommended: { name: "offline_bolt", type: "material-icons" },
  Sad: { name: "sentiment_dissatisfied", type: "material-icons-outlined" },
  Search: { name: "search", type: "material-icons" },
  Settings: { name: "settings", type: "material-icons-outlined" },
  ShoppingCart: { name: "shopping_cart", type: "material-icons" },
  Skeleton: { name: "horizontal_split", type: "material-icons" },
  SortBy: { name: "swap_vert", type: "material-icons" },
  Success: { name: "check_circle", type: "material-icons-outlined" },
  Subject: { name: "subject", type: "material-icons" },
  Star: { name: "star", type: "material-icons" },
  StarHalf: { name: "star_half", type: "material-icons" },
  SubdirectoryArrow: { name: "subdirectory_arrow_right", type: "material-icons" },
  Table: { name: "table_rows", type: "material-icons-outlined" },
  Terms: { name: "menu_book", type: "material-icons-outlined" },
  Text: { name: "text_snippet", type: "material-icons-outlined" },
  Time: { name: "access_time", type: "material-icons" },
  TrashBin: { name: "folder_delete", type: "material-icons-outlined" },
  Visa: { name: "visa", type: "assets", extension: "png" },
  VolumeDown: { name: "volume_down", type: "material-icons" },
  VolumeUp: { name: "volume_up", type: "material-icons" },
  VolumeOff: { name: "volume_off", type: "material-icons" },
  Upload: { name: "file_upload", type: "material-icons" },
  UserGroup: { name: "group", type: "material-icons-outlined" },
  Warning: { name: "warning", type: "material-icons-round" },
  Write: { name: "campaign", type: "material-icons-outlined" },
  Export: { name: "launch", type: "material-icons-outlined" },
  Square: { name: "crop_square", type: "material-icons-outlined" },
  Send: { name: "send", type: "material-icons" },
  List: { name: "list", type: "material-icons" },
  Sparkle: { name: "sparkle", type: "assets", extension: "svg" },
  ConversationResult: { name: "conversation_result", type: "assets", extension: "svg" },
  EditChat: { name: "edit_chat", type: "assets", extension: "svg" },
  SearchSparkle: { name: "search_sparkle", type: "assets", extension: "svg" },
};

// https://marella.me/material-icons/demo/
</script>
